import 'slick-carousel';

const $slider = $('.js-slider .slider__slides');

$slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    lazyLoad: 'progressive', //ondemand
    swipeToSlide: true,
});
//progressive: Loads the visible image as soon as the page is displayed and the other ones after everything else is loaded in the background ("loads the visible slides on init, and then progressively loads the rest of the slides on window.load()."). Should be used if the other images will be used most (or all) of the times the page is displayed.

//on-demand: Loads the visible image as soon as the page is displayed and the other ones only when they're displayed. ("[...] loads slides on demand. When a slide becomes visible (or on the before slide callback) the load is fired.") Should be used if the other images of the carousel are displayed very rarely.